import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { registerGuard } from "./Guard";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/gestione-tornei/gestione-manifestazioni",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/non-autorizzato",
        name: "NonAutorizzato",
        component: () => import("@/views/NonAutorizzato.vue"),
      },
      {
        path: "/comunicazioni",
        name: "comunicazioni",
        component: () =>
          import("@/views/comunicazioni/ComunicazioniInterneList.vue"),
      },
      {
        path: "/classifiche-sociali",
        name: "classifiche-sociali",
        component: () =>
          import("@/views/classifiche_sociali/ListaClassificheSociali.vue"),
      },
      {
        path: "/controller-iscritti-consolidati",
        name: "controller",
        component: () => import("@/views/Controller/ControllerTpra.vue"),
      },
      {
        path: "/gestione-sfide",
        name: "gestione-sfide",
        component: () => import("@/views/sfide/ListaSfide.vue"),
      },
      {
        path: "/gestione-insegnanti",
        name: "gestione-insegnanti",
        component: () =>
          import("@/views/insegnanti/ListaGestioneInsegnanti.vue"),
      },
      {
        path: "/gestione-coordinatori",
        name: "gestione-coordinatori",
        component: () => import("@/views/coordinatori/ListaCoordinatori.vue"),
      },
      {
        path: "/gestione-fiduciari",
        name: "gestione-fiduciari",
        component: () => import("@/views/fiduciari/ListaFiduciari.vue"),
      },
      {
        path: "/gestione-tornei/gestione-circuiti",
        name: "gestione-circuiti",
        component: () => import("@/views/circuiti/ListaCircuiti.vue"),
      },
      {
        path: "/gestione-giocatori/dettaglio-tessera",
        name: "dettaglio-tesserato",
        component: () => import("@/views/giocatori/DettaglioTesserato.vue"),
      },
      /* {
        path: "/gestione-campionati",
        name: "gestione-campionati",
        component: () => import("@/views/campionati/ListaCampionati.vue"),
      }, */
      {
        path: "/gestione-tornei/gestione-manifestazioni",
        name: "gestione-tornei",
        component: () =>
          import("@/views/manifestazioni/ListaManifestazioni.vue"),
      },
      {
        path: "/gestione-tornei/aggiungi-manifestazione",
        name: "aggiungi-manifestazione",
        component: () =>
          import("@/views/manifestazioni/AddEditManifestazione.vue"),
      },
      {
        path: "/gestione-tornei/modifica-manifestazione/:id",
        name: "modifica-manifestazione",
        component: () =>
          import("@/views/manifestazioni/EditManifestazioneV2.vue"),
      },

      {
        path: "/gestione-tornei/dettaglio-torneo",
        name: "dettaglio-torneo",
        component: () =>
          import("@/views/manifestazioni/DettaglioTorneoTab.vue"),
        children: [
          {
            path: "iscrizioni-torneo/:id",
            name: "iscrizioni-torneo",
            component: () =>
              import("@/views/manifestazioni/ListaIscrizioniTorneo.vue"),
          },
          {
            path: "tabellone-torneo/:id",
            name: "tabellone-torneo",
            component: () =>
              import("@/views/manifestazioni/TabelloneTorneo.vue"),
          },
          {
            path: "gestione-banner/:id",
            name: "banner-torneo",
            component: () =>
              import("@/views/manifestazioni/ListaBannerTorneo.vue"),
          },
          {
            path: "torneo-commenti/:id",
            name: "torneo-commenti",
            component: () =>
              import("@/views/manifestazioni/TorneiCommenti.vue"),
          },
          //commentato per lavorazione successiva
          // {
          //   path: "torneo-foto/:id",
          //   name: "torneo-foto",
          //   component: () => import("@/views/manifestazioni/TorneoFoto.vue"),
          // },
        ],
      },
      {
        path: "/gestione-circoli",
        name: "gestione-circoli",
        component: () => import("@/views/circoli/ListaCircoli.vue"),
      },
      {
        path: "/gestione-giocatori/lista-account-giocatori-tpra",
        name: "lista-account-giocatori-tpra",
        component: () => import("@/views/giocatori/ListaAccountGiocatori.vue"),
      },
      {
        path: "/gestione-giocatori/lista-giocatori-tesserati",
        name: "lista-giocatori-tesserati",
        component: () => import("@/views/giocatori/ListaTesserati.vue"),
      },
      {
        path: "/gestione-squadre/lista-campionati-squadre",
        name: "lista-campionati-squadre",
        component: () => import("@/views/squadre/ListaCampionatiSquadre.vue"),
      },
      {
        path: "/gestione-squadre/lista-squadre",
        name: "lista-squadre",
        component: () => import("@/views/squadre/ListaSquadre.vue"),
      },

      {
        path: "/gestione-squadre/lista-tornei-squadre",
        name: "lista-tornei-squadre",
        component: () => import("@/views/squadre/ListaTorneiSquadre.vue"),
      },
      {
        path: "/gestione-squadre/dettaglio-gironi-campionato-squadre",
        name: "dettaglio-torneo-campionato-squadre",
        component: () => import("@/views/squadre/DettaglioCampionatoTab.vue"),
        children: [
          {
            path: "iscrizioni-campionato/:id",
            name: "iscrizioni-torneo-campionato-squadre",
            component: () =>
              import("@/views/squadre/ListaIscrizioniCampionato.vue"),
          },
          {
            path: "tabellone-campionato/:id",
            name: "tabellone-torneo-campionato-squadre",
            component: () => import("@/views/squadre/TabelloneCampionato.vue"),
          },
        ],
      },

      {
        path: "/comunicati",
        name: "comunicati",
        component: () => import("@/views/comunicati/ListaComunicati.vue"),
      },
      {
        path: "/gestione-news",
        name: "gestione-news",
        component: () => import("@/views/news/ListaNews.vue"),
      },
      {
        path: "/gestione-news/modifica-news/:id",
        name: "modifica-news",
        component: () => import("@/views/news/AddEditNews.vue"),
      },
      {
        path: "/gestione-news/aggiungi-news/",
        name: "aggiungi-news",
        component: () => import("@/views/news/AddEditNews.vue"),
      },
      {
        path: "/gestione-utenti",
        name: "gestione-utenti",
        component: () => import("@/views/utenti/ListaUtenti.vue"),
      },
      // aspettare function oli
      {
        path: "/portale-assistenza",
        name: "portale-assistenza",
        component: () => import("@/views/assistenza/PortaleAssistenza.vue"),
      },
      {
        path: "/manutenzione",
        name: "Manutenzione",
        component: () => import("@/views/Manutenzione.vue"),
      },
      {
        path: "/tornei-non-inviati",
        name: "tornei-non-inviati",
        component: () =>
          import("@/views/tornei_non_inviati/ListaTorneiNoInvio.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layout/LayoutNoMenu.vue"),
    children: [
      {
        path: "/gestione-news-giocatori/accetta-news/:guid",
        name: "accetta-news",
        component: () => import("@/views/news/AddEditNews.vue"),
        meta: {
          noAuth: true,
        },
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

registerGuard(router);
router.beforeEach(() => {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

router.onError((error) => {
  // console.log(
  //   "QUESTO PERMETTE DI INTERCETTARE ERRORI NEL CARICAMNTO DEI FILE JS. DA IMPLEMENTARE LA GESTIONE DI QUEST'ERRORE",
  //   error,
  //   error.name,
  //   error.message
  // );
  window.location.reload();
});

export default router;
