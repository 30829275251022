import { ref, computed } from "vue";
import { msalInstance } from "@/authConfig";
import { alertFailed } from "@/composables/swAlert";
import { useFetchJSONFCH } from "@/functions/getBaseUrl";

export default function useSSOFunction() {
  const canLogout = ref(false);
  const puoNavigare = ref(false);
  const payloadRequests = ref({ id_user: localStorage.getItem("guid") });
  const { execute: executeLogout, statusCode } =
    useFetchJSONFCH("v1/sso/logout").post(payloadRequests);

  const { execute: executeLogin, statusCode: statusCodeLogin } =
    useFetchJSONFCH("v1/sso/login").post(payloadRequests);

  const { execute: executeStatus, statusCode: statusCodeStatus } =
    useFetchJSONFCH("v1/sso/user/status").post(payloadRequests);

  const isUnauthenticatedUrl = computed(() => {
    return window.location.href.includes(
      "/gestione-news-giocatori/accetta-news/"
    );
  });
  const loginFunction = async () => {
    if (isUnauthenticatedUrl.value) {
      puoNavigare.value = true;

      return;
    }

    payloadRequests.value.id_user = localStorage.getItem("guid");

    await executeLogin();
  };
  const statusFunction = async () => {
    if (!localStorage.getItem("guid") || isUnauthenticatedUrl.value) {
      puoNavigare.value = true;
      return;
    }
    payloadRequests.value.id_user = localStorage.getItem("guid")
      ? localStorage.getItem("guid")
      : localStorage.getItem(
          "msal.0ed33f8f-229e-4909-bb39-fe948d9a56b1.active-account"
        );

    await executeStatus();

    if (statusCodeStatus.value != 200) {
      puoNavigare.value = false;
      alertFailed("Per continuare, effettuare nuovamente il login").then(() => {
        localStorage.clear();
        msalInstance.logoutRedirect();
      });
    } else {
      canLogout.value = true;
      puoNavigare.value = true;
    }
  };

  const logoutFunction = async () => {
    payloadRequests.value.id_user = localStorage.getItem("guid");
    await executeLogout();
    if (statusCode.value === 200 || statusCode.value === 525) {
      localStorage.clear();
      puoNavigare.value = true;
      canLogout.value = true;
      msalInstance.logoutRedirect();
    } else {
      canLogout.value = false;
    }
  };

  return {
    statusFunction,
    loginFunction,
    logoutFunction,
    statusCodeLogin,
    statusCodeStatus,
    canLogout,
    puoNavigare,
  };
}
